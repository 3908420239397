import React from "react";
import styled from "styled-components";
// Components

const Container: React.FC<ContainerProps> = ({ children, className, maxWidth = "var(--screen-lg)", onClick, style }) => {
  return (
    <StyledContainer className={className} onClick={onClick} maxWidth={maxWidth} style={style}>
      {children}
    </StyledContainer>
  );
};

// Styled Components
const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 0 auto;
  padding: 0 1.6rem;
  > *:first-child {
    margin-top: 0;
  }
`;

// Types
type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  maxWidth?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
};
type StyledContainerProps = {
  maxWidth?: string;
  style?: React.CSSProperties;
};

export { Container };
