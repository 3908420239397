import React from "react";
// Components
import { Link } from "gatsby";
import { SEO } from "../components/SEO";
import { Section } from "../components/Section";
import { Container } from "../components/Container";

const NotFoundPage: React.FC = () => (
  <>
    <SEO title="404: Not found" />
    <Section>
      <Container>
        <h1>Oops! 404</h1>
        <h3>You just hit a route that doesn&#39;t exist.</h3>
        <Link to="/">Go Home</Link>
      </Container>
    </Section>
  </>
);

export default NotFoundPage;
